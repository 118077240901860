import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  useTheme,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {
  useGetMCQsQuery,
  useCreateMCQMutation,
  useUpdateMCQMutation,
  useDeleteMCQMutation,
} from '../../state/mcqApi'; 
import { useGetCategoryQuery } from '../../state/api';
import { toast } from 'react-toastify';

//const competitionType = [ 'Math', 'English', 'General', 'Ludu' ]
const statuses = ['Under Review', 'Publish'];
//const difficulties = ['easy', 'medium', 'hard'];

const MCQManagement = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [formData, setFormData] = useState({
    question: '',
    options: [
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
    ],
    correctAnswer: '',
    status: '',
    refLink: '',
    categoryId: '',
  });
  const [currentId, setCurrentId] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const choice = [ 'Option A', 'Option B', 'Option C', 'Option D'];

  const [searchForm, setSearchForm] = useState({
    categoryId:'',
    status: ''
  });

  const { data: apiResponse, isLoading } = useGetMCQsQuery(searchForm);
  const { data: apiCatagory } = useGetCategoryQuery();

  console.log('mcqList', apiResponse);

  const mcqs = Array.isArray(apiResponse?.data) ? apiResponse.data : [];
  const categories = Array.isArray(apiCatagory) ? apiCatagory : [];


  // console.log(mcqs);

  const [createMCQ] = useCreateMCQMutation();
  const [updateMCQ] = useUpdateMCQMutation();
  const [deleteMCQ] = useDeleteMCQMutation();

  const handleSearch = () => {

  }
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpen = (mcq = null) => {
    console.log(mcq);
    if (mcq) {
      setFormData({ ...mcq, categoryId: mcq.categoryId._id });
      setCurrentId(mcq._id);
    } else {
      setFormData({
        question: '',
        options: [
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
        ],
        correctAnswer: '',
        status: '',
        refLink: '',
        categoryId: '',
      });
      setCurrentId(null);
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // Confirmation Dialog Handlers
  const openConfirmationDialog = (action) => {
    setConfirmationAction(() => action);
    setConfirmationOpen(true);
  };

  const handleConfirm = () => {
    confirmationAction();
    setConfirmationOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationAction(null);
    setConfirmationOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = formData.options.map((option, i) => 
      i === index 
        ? { ...option, [field]: field === 'isCorrect' ? value === 'true' : value } 
        : option
    );
  
    setFormData((prev) => ({ ...prev, options: updatedOptions }));
  };

  const handleSubmit = async () => {
    const payload = {
      ...formData,
      correctAnswer: formData.options.find((option) => option.isCorrect)?.text || '',
    };

    let resp = {};
    try {
      if (currentId) {
        resp = await updateMCQ({ id: currentId, ...payload }).unwrap();
      } else {
        resp = await createMCQ(payload).unwrap();
      }
      const {message} = resp;
      toast.success(message);
      setOpen(false);
    } catch (error) {
      console.log(error)
      toast.error(error.data.message);
    }
   
  
    // openConfirmationDialog(async () => {
    //   if (currentId) {
    //     await updateMCQ({ id: currentId, ...payload });
    //   } else {
    //     await createMCQ(payload);
    //   }

    //   setOpen(false);
    // });
  };

  const handleDelete = async (id) => {
    openConfirmationDialog(async () => {
      try {
        const resp = await deleteMCQ(id).unwrap();
        const {message} = resp;
        toast.success(message);
      } catch (error) {
        console.log(error)
        toast.error(error.data.message);
      }
    });
  };

  const columns = [
    { field: "question", headerName: "Question", flex: 2 },
    { field: "categoryId", headerName: "Category", flex: 1, 
      valueGetter: (params) => params.row.categoryId?.name || "" // to work filter as well
    },
    { field: "serialNo", headerName: "Serial No", width: 120 },
    { field: "options", headerName: "Options", flex: 2, 
      renderCell: (params) => {
        // Check if params.value is an array and has items
        if (Array.isArray(params.value)) {
          return params.value.map(option => option.text).join(', ');
        }
        return '';
      }
    },
    { field: "correctAnswer", headerName: "Correct Answer", flex: 1 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="white" onClick={() => handleOpen(params.row)}>
            <Edit />
          </IconButton>
          {params.row.status === "Under Review" && (
            <IconButton color="warning" onClick={() => handleDelete(params.row._id)}>
              <Delete />
            </IconButton>
          )}
        </>
      ),
    },
  ];
  
  // const rows = mcqs.map((mcq) => ({
  //   id: mcq._id, // Required for DataGrid
  //   question: mcq.question,
  //   category: mcq.categoryId.name,
  //   serialNo: mcq.serialNo,
  //   options: mcq.options.map((option) => option.text).join(", "),
  //   correctAnswer: mcq.correctAnswer,
  //   status: mcq.status,
  // }));

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      
      <div className='row'>
        <div className='col-md-3'>
            <Button variant="contained" color="primary" onClick={() => handleOpen()}>
            Create New MCQ
          </Button>
        </div>
        <div className='col-md-9'>
          <div className='row'>
            <div className='col-md-8'></div>
            <div className='col-md-2'>
              <TextField
                select
                margin="dense"
                name="categoryId"
                label="All Category"
                fullWidth
                value={searchForm.categoryId}
                onChange={handleSearchChange}
                size='small'
              >
                <MenuItem key={1211} value="">
                    All Categories
                </MenuItem>
                {categories.map((obj, k) => (
                  <MenuItem key={k} value={obj._id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className='col-md-2'>
              <TextField
                select
                margin="dense"
                name="status"
                label="All Status"
                fullWidth
                value={searchForm.status}
                onChange={handleSearchChange}
                size='small'
              >
                <MenuItem key={121143} value="">
                    All Status
                </MenuItem>
                {statuses.map((val, k) => (
                  <MenuItem key={k} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      </div>
      

      {/* Table */}
      <DataGrid
        rows={mcqs}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row._id} // need it because we dont have mcqs.id property
        autoHeight
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-root": {
            overflowX: "auto", // Enable horizontal scroll
          },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      />

      {/* Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{currentId ? 'Edit MCQ' : 'Create MCQ'}</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            name="categoryId"
            label="Select Category"
            fullWidth
            value={formData.categoryId}
            onChange={handleChange}
          >
            {categories.map((obj, k) => (
              <MenuItem key={k} value={obj._id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="question"
            label="Write your question"
            fullWidth
            value={formData.question}
            onChange={handleChange}
          />
          <FormControl component="fieldset" margin="normal" fullWidth>
            <RadioGroup
              name="options"
              value={formData.correctAnswer}
              onChange={(e) => {
                const updatedOptions = formData.options.map((option, index) => ({
                  ...option,
                  isCorrect: option.text === e.target.value,
                }));
                setFormData((prev) => ({
                  ...prev,
                  options: updatedOptions,
                  correctAnswer: e.target.value,
                }));
              }}
            >
               <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} marginBottom={2}>
                <div>Write the options</div>
                <div>Choose correct answer</div>
              </Stack>

              {formData.options.map((option, index) => (
                <Box key={index} display="flex" alignItems="center" gap={2} my={1}>
                  <TextField
                    label={choice[index]}
                    fullWidth
                    value={option.text}
                    onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
                  />
                  <FormControlLabel
                    value={option.text}
                    control={<Radio />}
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          <>
          {( user?.role === 'admin' || user?.role === 'superadmin') && (
            <TextField
            select
            margin="dense"
            name="status"
            label="Status"
            fullWidth
            value={formData.status}
            onChange={handleChange}
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          )}
          </>
          <TextField
            margin="dense"
            name="refLink"
            label="Reference Link"
            fullWidth
            value={formData.refLink}
            onChange={handleChange}
          />
          {/* <TextField
            select
            margin="dense"
            name="difficulty"
            label="Difficulty"
            fullWidth
            value={formData.difficulty}
            onChange={handleChange}
          >
            {difficulties.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to proceed with this action?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MCQManagement;