import React,{useState} from "react";
import { useCreatePostMutation, useDeleteUserMutation, useGetAdminsQuery, useUpdateUserMutation } from "../../state/api";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Avatar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import defaultProfileImage from "assets/profile.jpeg"; 
import { Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import countryList from "./countryList";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Admin = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useGetAdminsQuery();

  const [createPost] = useCreatePostMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();


  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  };

  const statusList     = ['Active','Deactive'];

  const [pageWindow, setPageWindow] = useState('list');
  const [formData, setFormData] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [socials, setSocials] = useState([{ platform: '', url: '' }]);
  const [imagePreview, setImagePreview] = useState();



  const getFormData =  (e) => {
    if (e.target.type === "file") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else if (e.target.name === "country") {
      setFormData({ ...formData, country: e.target.value });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }  
   
  // Handle change for individual social input
  const handleChange = (index, field, value) => {
    const updatedSocials = [...socials];
    updatedSocials[index][field] = value;
    setSocials(updatedSocials);
  };

  // Add a new empty social entry
  const handleAddSocial = () => {
    setSocials([...socials, { platform: '', url: '' }]);
  };

  // Remove a social entry
  const handleRemoveSocial = (index) => {
    const updatedSocials = socials.filter((_, i) => i !== index);
    setSocials(updatedSocials);
  };


  const handleCancelClick = () => {
    setPageWindow('list');
  }

  const handleAddClick = () => {
    setPageWindow('add_edit');
    setFormData({});
    setSocials([{platform:'', url:''}]);
    setIsEditing(false);
    // navigate('/user',{
    //   state: params
    // })
  }
  const handleViewClick = (params) => {
    setPageWindow('view');
    setFormData(params);
    // navigate('/user',{
    //   state: params
    // })
  }
  const handleEditClick = (params) => {
    setPageWindow('add_edit');
    const { socials, ...rest } = params;
    setFormData(rest);
    setImagePreview(`${process.env.REACT_APP_API_URL}/img/${rest?.image }`);

    // const editableRow = data.filter( (e) => e._id==id);
    setIsEditing(true);

    // navigate('/add-edit-teacher',{
    //   state: params
    // })
  }
  const handleImagePreview = (e) => {
    const { name, files } = e.target;
    getFormData(e);

    if (name === "image" && files && files[0]) {
      setImagePreview(URL.createObjectURL(files[0]));
    }
  }

  // add/update submit button handler
  const handleSubmit = async (e) =>{
    e.preventDefault();
    let response = {};

    const formDataToSend = new FormData();

    for (const key in formData) {
      if (typeof formData[key] === "object" && !(formData[key] instanceof File)) {
        formDataToSend.append(key, JSON.stringify(formData[key])); 
      } else {
        formDataToSend.append(key, formData[key]); 
      }
    }
    if (socials) {
      formDataToSend.append("socials", JSON.stringify(socials));
    }

    try {
      if ( isEditing ) {
        await updateUser({id: formData._id, formData: formDataToSend}).unwrap();
        toast.success(response.message);
        setPageWindow('list');
      } else {
        await createPost(formDataToSend).unwrap();
        toast.success(response.message);
        setPageWindow('list');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleDeleteClick = async (e) => {
    if ( window.confirm("Are you sure to delete?") ) {
      const resp = await deleteUser(e._id).unwrap();
      const {status, message} = resp;
      if ( status == 'success' ){
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
    return false;
  
  }
  
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
      // renderCell: (params) => {
      //   return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      // },
    },
    {
      field: "expertise",
      headerName: "Expertise",
      flex: 1,
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {/* <Button variant="text" color="secondary" onClick={() => handleViewClick(params.row)}>
              View
            </Button> */}
            <Button variant="text" color="secondary" onClick={()=>handleEditClick(params.row)}>
              Edit
            </Button>
            <Button variant="text" color="secondary" onClick={() => handleDeleteClick(params.row)}>
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];


  if (pageWindow === "add_edit") {
    return (
      <Box sx={{ padding: { xs: 1, md: 3 } }}>
        {/* Header */}
        <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          {isEditing ? "Edit Admin" : "Add Admin"}
        </Typography>

        {/* Form Container */}
        <Grid container spacing={3}>
          {/* Profile Picture */}
          <Grid item xs={12} md={4}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar
                src={ imagePreview || defaultProfileImage }
                alt="Profile Picture"
                sx={{ width: 150, height: 150 }}
              />
              <Box mt={2}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<PhotoCamera />}
                >
                  Change Picture
                  <input
                    hidden
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImagePreview}
                  />
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Form Fields */}
          <Grid item xs={12} md={8}>
            <Box component="form" noValidate>
              {/* Personal Details */}
              <Typography variant="subtitle1" gutterBottom>
                Personal Details
              </Typography>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData?.name || ""}
                onChange={getFormData}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                type="email"
                name="email"
                value={formData?.email || ""}
                onChange={getFormData}
                sx={{ mb: 2 }}
              />
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={formData?.phone || ""}
                  onChange={getFormData}
                />
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  value={formData?.password || ""}
                  onChange={getFormData}
                />
              </Stack>

              {/* Work Details */}
              <Typography variant="subtitle1" gutterBottom>
                Work Details
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Expertise In"
                  name="expertise"
                  value={formData?.expertise || ""}
                  onChange={getFormData}
                />
                <TextField
                  fullWidth
                  label="Organization"
                  name="organization"
                  value={formData?.organization || ""}
                  onChange={getFormData}
                />
              </Stack>

              {/* Status and Country */}
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    name="status"
                    value={formData?.status || ""}
                    onChange={getFormData}
                  >
                    {statusList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    name="country"
                    value={formData?.country || ""}
                    onChange={getFormData}
                  >
                    {countryList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              {/* Social Links */}
              <Typography variant="subtitle1" gutterBottom>
                Social Links
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Typography variant="body1">Manage Social Links</Typography>
                <Button variant="contained" onClick={handleAddSocial}>
                  Add Social Link
                </Button>
              </Stack>
              {socials.map((social, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ mb: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Platform (e.g., Facebook, LinkedIn)"
                    name={`socials[${index}].platform`}
                    value={social.platform}
                    onChange={(e) =>
                      handleChange(index, "platform", e.target.value)
                    }
                  />
                  <TextField
                    fullWidth
                    label="URL"
                    name={`socials[${index}].url`}
                    value={social.url}
                    onChange={(e) => handleChange(index, "url", e.target.value)}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveSocial(index)}
                  >
                    Remove
                  </Button>
                </Stack>
              ))}

              {/* Save and Cancel Buttons */}
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ width: "20%" }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "80%" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      <Header title="Admin" subtitle="Managing list of Admin" />
      <Box sx={{ width: '100%', textAlign: "right"}}>
      <Button onClick={handleAddClick} sx={{...defaultTheme,fontWeight: 'bold'}}>Add Admin</Button>
      </Box>
     
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />

      </Box>
    </Box>
  );
};

export default Admin;
