const countryList = [
    {
      name: "United States",
      code: "US",
      city: "Washington D.C.",
      zip: "20500",
      isp: "Comcast",
      lat: "38.8977",
      lon: "-77.0365",
      timezone: "EST",
    },
    {
      name: "India",
      code: "IN",
      city: "New Delhi",
      zip: "110001",
      isp: "Jio",
      lat: "28.6139",
      lon: "77.2090",
      timezone: "IST",
    },
    {
      name: "Canada",
      code: "CA",
      city: "Ottawa",
      zip: "K1A 0B1",
      isp: "Bell Canada",
      lat: "45.4215",
      lon: "-75.6972",
      timezone: "EST",
    },
    {
      name: "United Kingdom",
      code: "UK",
      city: "London",
      zip: "SW1A 1AA",
      isp: "BT Group",
      lat: "51.5074",
      lon: "-0.1278",
      timezone: "GMT",
    },
    {
      name: "Australia",
      code: "AU",
      city: "Canberra",
      zip: "2600",
      isp: "Telstra",
      lat: "-35.2809",
      lon: "149.1300",
      timezone: "AEST",
    },
    {
      name: "Germany",
      code: "DE",
      city: "Berlin",
      zip: "10117",
      isp: "Deutsche Telekom",
      lat: "52.5200",
      lon: "13.4050",
      timezone: "CET",
    },
    {
      name: "France",
      code: "FR",
      city: "Paris",
      zip: "75000",
      isp: "Orange",
      lat: "48.8566",
      lon: "2.3522",
      timezone: "CET",
    },
    {
      name: "Japan",
      code: "JP",
      city: "Tokyo",
      zip: "100-0001",
      isp: "NTT",
      lat: "35.6895",
      lon: "139.6917",
      timezone: "JST",
    },
    {
      name: "China",
      code: "CN",
      city: "Beijing",
      zip: "100000",
      isp: "China Mobile",
      lat: "39.9042",
      lon: "116.4074",
      timezone: "CST",
    },
    {
      name: "Brazil",
      code: "BR",
      city: "Brasília",
      zip: "70000-000",
      isp: "Oi",
      lat: "-15.8267",
      lon: "-47.9218",
      timezone: "BRT",
    },
  ];
  
  export default countryList;