import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  OutlinedInput,
  useTheme,
  Dialog,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import {
  useGetAdsQuery,
  useCreateAdMutation,
  useUpdateAdMutation,
  useDeleteAdMutation,
} from "../../state/adsApi";
import Header from "components/Header";

const countries = ["Bangladesh", "India", "USA", "China", "Malaysia"];
const adTypes = ["Image", "Video", "Slider", "Popup"];
const adFileTypes = ["Image", "Video", "Google"];
const paymentStatuses = ["Paid", "Unpaid"];
const statuses = ["Active", "Inactive", "Done"];

const Ads = () => {
  const theme = useTheme();
  const { data: adsResponse, isLoading } = useGetAdsQuery();
  const [createAd] = useCreateAdMutation();
  const [updateAd] = useUpdateAdMutation();
  const [deleteAd] = useDeleteAdMutation();

  const [pageWindow, setPageWindow] = useState("list");
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);


  const rows =
    adsResponse?.data?.map((item) => ({
      ...item,
      id: item._id, // Map `_id` to `id` for DataGrid
    })) || [];

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
    }));

    if (name === "adFile" && files && files[0]) {
      setFilePreview(URL.createObjectURL(files[0])); // Generate preview URL
    }
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      allowCountries: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleCancel = () => {
    setPageWindow("list");
    setFilePreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adData = new FormData();
    Object.entries(formData).forEach(([key, value]) => adData.append(key, value));

    try {
      if (isEditing) {
        await updateAd({ id: formData._id, formData: adData }).unwrap();
        toast.success("Ad updated successfully!");
      } else {
        await createAd(adData).unwrap();
        toast.success("Ad created successfully!");
      }
      setPageWindow("list");
    } catch (error) {
      toast.error("An error occurred while saving the ad.");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this ad?")) {
      try {
        await deleteAd(id).unwrap();
        toast.success("Ad deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete the ad.");
      }
    }
  };

  const handlePreview = (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const columns = [
    { field: "adType", headerName: "Ad Type", flex: 1 },
    { field: "adFileType", headerName: "File Type", flex: 1 },
    { field: "viewLimit", headerName: "View Limit", flex: 1 },
    { field: "duration", headerName: "Duration", flex: 1 },
    { field: "viewed", headerName: "Viewed", flex: 1 },
    { field: "creator", headerName: "Creator", flex: 1 },
    { field: "cost", headerName: "Cost", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            color="success"
            onClick={() => handlePreview(params.row)}
          >
            Preview
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              setPageWindow("add_edit");
              setFormData(params.row);
              setIsEditing(true);
              setFilePreview(`${process.env.REACT_APP_API_URL}/img/${params.row?.adFile}`)
            }}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </Stack>
      ),
    },
  ];

  if (pageWindow === "add_edit") {
    return (
      <Box padding={2}>
        <Header title="Ads" subtitle={isEditing ? "Edit Ad" : "Add Ad"} />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Ad Type</InputLabel>
              <Select
                name="adType"
                value={formData.adType || ""}
                onChange={handleInputChange}
              >
                {adTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>File Type</InputLabel>
              <Select
                name="adFileType"
                value={formData.adFileType || ""}
                onChange={handleInputChange}
              >
                {adFileTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Ad Link"
              name="adLink"
              value={formData.adLink || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="View Limit"
              name="viewLimit"
              type="number"
              value={formData.viewLimit || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Duration (seconds)"
              name="duration"
              type="number"
              value={formData.duration || ""}
              onChange={handleInputChange}
            />

            <TextField
              label="Cost"
              name="cost"
              type="number"
              value={formData.cost || ""}
              onChange={handleInputChange}
            />

            <FormControl fullWidth>
              <InputLabel>Payment Status</InputLabel>
              <Select
                name="paymentStatus"
                value={formData.paymentStatus || ""}
                onChange={handleInputChange}
              >
                {paymentStatuses.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status || ""}
                onChange={handleInputChange}
              >
                {statuses.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Allowed Countries</InputLabel>
              <Select
                multiple
                value={formData.allowCountries || []}
                onChange={(event) => {
                  handleCountryChange(event); // Update the selected values
                  setMenuOpen(false); // Close the dropdown
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8, // Adjust dropdown height if needed
                      width: 250,
                    },
                  },
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country} onClick={() => setMenuOpen(false)}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {filePreview && (
              <Box>
                {formData.adFileType === "Video" ? (
                  <video width="100%" controls>
                    <source src={filePreview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={filePreview}
                    alt="Preview"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </Box>
            )}

            <Button
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon />}
            >
              Upload File
              <input
                hidden
                type="file"
                name="adFile"
                onChange={handleInputChange}
              />
            </Button>

            <Stack direction="row" spacing={2}>
              <Button variant="contained" color="primary" type="submit">
                {isEditing ? "Update" : "Submit"}
              </Button>
              <Button variant="contained" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    );
  }

  return (
    <Box padding={2}>
      <Header title="Ads" subtitle="Manage your ads" />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setPageWindow("add_edit");
          setFormData({});
          setIsEditing(false);
        }}
        style={{ marginBottom: "1rem" }}
      >
        Create Ad
      </Button>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-root": {
            overflowX: "auto", // Enable horizontal scroll
          },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
        }}
      />

      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Box>
          {selectedFile?.adFileType === "Video" ? (
            <video width="100%" controls>
              <source src={`${process.env.REACT_APP_API_URL}/img/${selectedFile?.adFile}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={`${process.env.REACT_APP_API_URL}/img/${selectedFile?.adFile}`}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default Ads;