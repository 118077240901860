import React from 'react';
import './style.css'; // Make sure to create this CSS file

const DeletePage = () => {
  return (
    <div className="release-message">
      <div className="row">
        <div className="col-md-12">
          <div className="p-5">
            <h1 className="text-center m-4">How to Delete Your Account from Our Server</h1>
            <h4>Steps to Delete Your Account in the App</h4>
            <ol>
              <li>Open the app on your device.</li>
              <li>Log in to your account.</li>
              <li>Click on the Navigation Icon (first screenshot)</li>
              <li>Find the Delete Account (second screenshot) option and click on it.</li>
              <li>Confirm the deletion by following the prompts.</li>
              <li>Once you confirm, your account will be deleted.</li>
            </ol>
            <strong>Note: Once you delete your account, all data will be permanently removed and cannot be recovered.</strong>
            <div className='responsive-container'>
              <img src="/images/step1.jpg" alt="Click to delete account" width="300" />
              <img src="/images/step2.jpg" alt="Click to delete account" width="300" />
            </div>
            <p>If you encounter any issues or have questions, please contact us at <a href="mailto:timesshop.xyz1@gmail.com">timesshop.xyz1@gmail.com</a>.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePage;
