import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  Chip,
  TextField,
  IconButton,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import defaultProfileImage from "assets/profile.jpeg";

const ProfileDetails = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: userData.name,
    role: userData.role,
    phone: "+1 123 456 7890",
    address: "525 E 68th Street, New York, NY",
    email: "hello@jeremyrose.com",
    website: "www.jeremyrose.com",
    birthday: "June 5, 1992",
    gender: "Male",
    workplaces: [
      { company: "Spotify New York", companyAddress: "170 William Street, New York, NY" },
    ],
    image: userData?.image || defaultProfileImage,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleWorkplaceChange = (index, field, value) => {
    const updatedWorkplaces = [...formData.workplaces];
    updatedWorkplaces[index][field] = value;
    setFormData({ ...formData, workplaces: updatedWorkplaces });
  };

  const handleAddWorkplace = () => {
    setFormData({
      ...formData,
      workplaces: [...formData.workplaces, { company: "", companyAddress: "" }],
    });
  };

  const handleRemoveWorkplace = (index) => {
    const updatedWorkplaces = formData.workplaces.filter((_, i) => i !== index);
    setFormData({ ...formData, workplaces: updatedWorkplaces });
  };

  const handleSave = () => {
    dispatch({ type: "UPDATE_USER_DETAILS", payload: formData });
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFormData({
      name: userData.name,
      role: userData.role,
      phone: "+1 123 456 7890",
      address: "525 E 68th Street, New York, NY",
      email: "hello@jeremyrose.com",
      website: "www.jeremyrose.com",
      birthday: "June 5, 1992",
      gender: "Male",
      workplaces: [
        { company: "Spotify New York", companyAddress: "170 William Street, New York, NY" },
      ],
      image: userData?.image || defaultProfileImage,
    });
    setIsEditing(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({ ...prev, image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 1000,
        margin: "auto",
        padding: 5,
        backgroundColor: "transparent",
      }}
    >
      <Grid container spacing={3}>
        {/* Profile Picture */}
        <Grid item xs={12} md={4}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center">
            <Avatar
              alt="Profile Picture"
              src={formData.image}
              sx={{ width: 150, height: 150 }}
            />
            {isEditing && (
              <Box mt={2}>
                <IconButton component="label" color="white">
                  <PhotoCamera />
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                  />
                </IconButton>
                <Typography variant="caption" color="text.secondary">
                  Change Picture
                </Typography>
              </Box>
            )}
          </Box>
          <Typography variant="h6" align="center" mt={2}>
            {formData.name}
          </Typography>
          <Typography variant="body2" align="center" color="text.secondary">
            {formData.role}
          </Typography>
        </Grid>

        {/* Details Section */}
        <Grid item xs={12} md={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Profile</Typography>
            <Typography variant="h6" color="primary">
              8.6 ★
            </Typography>
          </Box>

          <Box mt={2}>
            {!isEditing ? (
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: 1 }}
                onClick={() => setIsEditing(true)}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ mr: 1 }}
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Form / Display Details */}
          {!isEditing ? (
            <Box>
              <Box mb={2}>
                <Typography variant="subtitle1" gutterBottom>
                  Work
                </Typography>
                {formData.workplaces.map((workplace, index) => (
                  <Box key={index} mb={1}>
                    <Typography variant="body2">
                      {workplace.company}{" "}
                      <Chip label="Primary" size="small" color="primary" />
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {workplace.companyAddress}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Contact Information */}
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Contact Information
                </Typography>
                <Typography variant="body2">
                  <strong>Phone:</strong> {formData.phone}
                </Typography>
                <Typography variant="body2">
                  <strong>Address:</strong> {formData.address}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong> {formData.email}
                </Typography>
                <Typography variant="body2">
                  <strong>Website:</strong> {formData.website}
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              {/* Basic Information */}
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Basic Information
                </Typography>
                <Typography variant="body2">
                  <strong>Birthday:</strong> {formData.birthday}
                </Typography>
                <Typography variant="body2">
                  <strong>Gender:</strong> {formData.gender}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box component="form" noValidate>
              <Typography variant="subtitle1" gutterBottom>
                Work
              </Typography>
              {formData.workplaces.map((workplace, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <TextField
                    fullWidth
                    label="Company"
                    value={workplace.company}
                    onChange={(e) =>
                      handleWorkplaceChange(index, "company", e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    fullWidth
                    label="Company Address"
                    value={workplace.companyAddress}
                    onChange={(e) =>
                      handleWorkplaceChange(index, "companyAddress", e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveWorkplace(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                color="primary"
                onClick={handleAddWorkplace}
              >
                Add Workplace
              </Button>

              <Divider sx={{ my: 2 }} />

              {/* Contact Information */}
              <Typography variant="subtitle1" gutterBottom>
                Contact Information
              </Typography>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Website"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" gutterBottom>
                Basic Information
              </Typography>
              <TextField
                fullWidth
                label="Birthday"
                name="birthday"
                value={formData.birthday}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileDetails;