import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useGetCustomersQuery();

  const [filteredRowCount, setFilteredRowCount] = useState(0);

  // Handlers for viewing and editing
  function handleViewClick(params) {
    navigate("/user", {
      state: params,
    });
  }
  function handleEditClick(params) {
    navigate("/edit-customer", {
      state: params,
    });
  }

  // Column definitions
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.5,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
      valueGetter: (params) => params.row.country?.name || "",
    },
    {
      field: "organization",
      headerName: "Institute",
      flex: 1,
    },
    {
      field: "rank",
      headerName: "Rank",
      flex: 0.5,
    },
    {
      field: "myRefTotal",
      headerName: "Ref Users",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Button variant="text" color="secondary" onClick={() => handleViewClick(params.row)}>
              View
            </Button>
            <Button variant="text" color="secondary" onClick={() => handleEditClick(params.row)}>
              Edit
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Users" subtitle="List of all app users" />
        {/* Row Count Display */}
        <Box mt={2}>
          <Typography variant="subtitle1">
            Total Rows: {data?.length || 0}
          </Typography>
          <Typography variant="subtitle1">
            Filtered Rows: {filteredRowCount}
          </Typography>
        </Box>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          // Event to track filtered row count
          onFilterModelChange={(filterModel) => {
            if (filterModel.items.length > 0) {
              const filteredRows = data.filter((row) => {
                return filterModel.items.every((filter) => {
                  const rowValue = row[filter.columnField]; // Value from the row
                  const filterValue = filter.value; // Value from the filter input
          
                  // Ensure both rowValue and filterValue are defined before performing operations
                  if (rowValue == null || filterValue == null) return false;
          
                  // For strings, use case-insensitive comparison
                  if (typeof rowValue === "string") {
                    return rowValue.toLowerCase().includes(filterValue.toLowerCase());
                  }
          
                  // For numbers, compare values directly
                  if (typeof rowValue === "number") {
                    return rowValue === Number(filterValue);
                  }
          
                  // For unsupported types, return false
                  return false;
                });
              });
          
              setFilteredRowCount(filteredRows.length);
            } else {
              setFilteredRowCount(data?.length || 0);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Customers;