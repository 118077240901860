import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetHistoriesQuery } from "../../state/api"; // Create this API slice
import Header from "components/Header";

const History = () => {
  const theme = useTheme();
  const { data: histories, isLoading } = useGetHistoriesQuery();

  const rows = histories?.map((item) => ({
    id: item._id,
    gameId: item.gameId,
    winnerUser: item.winnerUserId?.name || "Unknown",
    looserUser: item.looserUserId?.name || "Unknown",
    categorySlug: item.categoryText,
    level: item.level,
    winnerLevel: item.winnerLevel,
    looserLevel: item.looserLevel,
    dateTime: new Date(item.dateTime).toLocaleString(),
  })) || [];

  const columns = [
    { field: "gameId", headerName: "Game ID", flex: 1 },
    { field: "winnerUser", headerName: "Winner", flex: 1 },
    { field: "looserUser", headerName: "Looser", flex: 1 },
    { field: "categorySlug", headerName: "Category", flex: 1 },
    { field: "level", headerName: "Level", flex: 1 },
    { field: "winnerLevel", headerName: "Winner Level", flex: 1 },
    { field: "looserLevel", headerName: "Looser Level", flex: 1 },
    { field: "dateTime", headerName: "Date Time", flex: 1 },
  ];

  return (
    <Box padding={2}>
      <Header title="Game History" subtitle="View the history of games played" />
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
        autoHeight
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: theme.palette.background.alt },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: theme.palette.primary.light },
        }}
      />
    </Box>
  );
};

export default History;