import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetMcqCompetitionsQuery } from "../../state/api"; // Create this API slice
import Header from "components/Header";

const McqCompetition = () => {
  const theme = useTheme();
  const { data: competitions, isLoading } = useGetMcqCompetitionsQuery();

  const rows =
    competitions?.map((comp) => ({
      id: comp.competitionId || 123,
      category: comp.category || "Unknown",
      categorySlug: comp.categorySlug,
      level: comp.level,
      participants: comp.participants?.map((p) => p?.name || "Unknown").join(", "),
      questionsCount: comp?.questions?.length || 10,
      status: comp.status,
      duration: comp.duration,
      winner: comp.winnerUserId?.name || "N/A",
      looser: comp.looserUserId?.name || "N/A",
      startTime: comp.startTime ? new Date(comp.startTime).toLocaleString() : "N/A",
    })) || [];

  const columns = [
    { field: "category", headerName: "Category", flex: 1 },
    // { field: "categorySlug", headerName: "Category Slug", flex: 1 },
    { field: "level", headerName: "Level", flex: 1 },
    { field: "participants", headerName: "Participants", flex: 2 },
    // { field: "questionsCount", headerName: "Questions", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "duration", headerName: "Duration (s)", flex: 1 },
    { field: "winner", headerName: "Winner", flex: 1 },
    { field: "looser", headerName: "Looser", flex: 1 },
    { field: "startTime", headerName: "Start Time", flex: 1 },
  ];

  return (
    <Box padding={2}>
      <Header title="MCQ Competitions" subtitle="View all MCQ competitions and their details" />
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
        autoHeight
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: theme.palette.background.alt },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: theme.palette.primary.light },
        }}
      />
    </Box>
  );
};

export default McqCompetition;